import { SearchUserOptionsModel } from '@shared/models/search.user.options.model';
import { TaskTemplateDto } from '@core/services/dto/task-template.dto';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreateTaskStateService } from './create-task.state.service';
import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import { CustomSnackbarService } from '@core/services/custom-snackbar.service';
import { InstancesModel } from '@shared/models/instances.model';
import { SearchMemberOptionsModel } from '@shared/models/search.member.options.model';
import { Task, UserNameModel, SearchAccountOptionsModel, SearchConversationOptionsModel, TaskCreateInterface, SendEmailRequestModel } from '@shared/models';
import { EmptyTaskTemplateDropdownOption, NoneTaskTemplateDropdownOption, PriorityOptions, TypeOptions } from '@shared/components/create-task/create-task.config';
import { SelectOption } from '@shared/interfaces';
import { GlobalStateService } from '@core/services/global.state.service';
import { UploadFileComponent } from '@shared/components/upload-file/upload-file.component';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { GLOBAL_SETTINGS } from '@shared/constants/global-settings';
import { EmailTemplateDto } from '@core/services/dto';
import { takeUntil } from 'rxjs/operators';
import { FileTypeEnum, TaskPriorityEnum, TaskTypeEnum } from '@shared/enums';
import { SearchMemberProspectOptionsModel } from '@shared/models/search.member.prospect.options.model';
import { MatDialogRef } from '@angular/material/dialog';
import { AddWorkflowModalComponent } from 'app/modules/dialogs/add-workflow-modal/add-workflow-modal.component';
import { UserGroupNameModel } from '@shared/models/userGroup-name.model';
import { MemberProspectInstancesModel } from '@shared/models/memberprospectinstances.model';
import { TaskApiService, UserGroupManagementApiService } from '@core/services/api.services';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { EnailNotificationApiService } from '@core/services/api.services/email-notification-api.service';
import { EmailNotificationPermissionModel } from '@shared/models/email-notificationmodel';
import { EmailNotificationDto } from '@core/services/dto/email-notification.dto';
import { UserManagementStateService } from 'app/modules/setup/user-management/user-management.state.service';
import { GroupTypeStringEnum } from '@shared/enums/group-type.enum';

@Component({
  selector: 'app-create-task',
  templateUrl: './create-task.component.html',
  styleUrls: ['./create-task.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CreateTaskComponent extends autoUnsubscribeMixin() implements OnInit, AfterViewInit, OnDestroy {
  @Input() preselectedMember: SearchMemberProspectOptionsModel;
  @Input() preselectedConversation: SearchConversationOptionsModel;
  @Input() preselectedAccounts: SearchAccountOptionsModel[] = [];
  @Output() closeDialog: EventEmitter<any> = new EventEmitter();
  @Output() formChanged: EventEmitter<boolean> = new EventEmitter();
  @ViewChild(UploadFileComponent) uploadFile: UploadFileComponent;
  @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger: MatAutocompleteTrigger;
  form: FormGroup;
  task: TaskCreateInterface;
  formLinks: SearchMemberProspectOptionsModel[] = [];
  taskTemplatesOptions: SelectOption<number>[] = [];
  taskTemplates: TaskTemplateDto[] = [];
  user: UserGroupNameModel;
  defaultUserName = new Subject<SearchMemberOptionsModel>();
  todayDate = new Date();
  emailTemplates: number[] = [];
  taskEmails: EmailTemplateDto[] = [];
  private assigneeId: string;
  selectedUser: any;
  isDefaultAssignerSet = false;
  FileTypeEnum = FileTypeEnum;
  GLOBAL_SETTINGS = GLOBAL_SETTINGS;
  TypeOptions = TypeOptions;
  PriorityOptions = PriorityOptions;
  users$ = this.state.users$();
  isPanelClosed = false;
  emailNotificationPermission: EmailNotificationPermissionModel[];
  initialValue: any = {};
  isRepRole = false;
  clearTimeoutSb: any;
  isFirstInstanceLoad = true;
  constructor(
    private fb: FormBuilder,
    private state: CreateTaskStateService,
    private toast: CustomSnackbarService,
    private globalState: GlobalStateService,
    public dialogRef: MatDialogRef<AddWorkflowModalComponent>,
    private taskService: TaskApiService,
    private htmlElementRef: ElementRef,
    private service: EnailNotificationApiService,
    private userstate: UserManagementStateService,
    private groupState: UserGroupManagementApiService
  ) {
    super();
    this.selectedUser = {
      label: this.globalState.user.firstName + ' ' + this.globalState.user.lastName,
      ref: this.globalState.user.id,
      type: null,
      value: null
    };
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.state.member$().subscribe(
      data => {
        if (data) {
          this.preselectedMember = new SearchMemberProspectOptionsModel(data);
        }
      }
    );

    this.user = { ...this.globalState.userGroup };
    this.assigneeId = this.user.id;

    this.form = this.fb.group({
      templateId: [null],
      type: [TaskTypeEnum[TaskTypeEnum.General], Validators.required],
      priority: [TaskPriorityEnum[TaskPriorityEnum.Low], Validators.required],
      description: ['', [Validators.maxLength(1000), Validators.minLength(3), Validators.required]],
      dueDate: [''],
      assigneeId: ['']
    });

    this.state.getTaskTemplates();
    this.form.controls.assigneeId.valueChanges.subscribe((name: SearchUserOptionsModel | string) => {
      if (!name) {
        this.isRepRole = false;
        return;
      }

      if (typeof name === 'object') {
        this.assigneeId = name.ref;
      }
      else if (name?.length >= GLOBAL_SETTINGS.minLengthToTriggerSearch && this.isDefaultAssignerSet) {
        this.state.getSearchedUserGroups(name);
      }
    });

    this.state.taskTemplates$
      .subscribe(taskTemplates => {
        taskTemplates.length
          ? this.initializeTaskTemplatesDropdown(taskTemplates)
          : this.initializeEmptyTaskTemplatesDropdown();
        this.defaultUserName.next({
          label: this.user.firstName + ' ' + this.user.lastName,
          ref: this.user.id,
          type: null,
          value: null
        });
        this.isDefaultAssignerSet = true;
      });

    this.state.emailTemplates$
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(response => {
        this.taskEmails = response.data;
        this.emailTemplates = this.taskEmails.map(e => e.id);
      });

    this.form.controls.templateId.valueChanges.subscribe(value => {
      this.applyTaskTemplate(value);
    });

    this.service.getEmailNotificationPermission(false).subscribe(
      (response: EmailNotificationDto[]) => {
        this.emailNotificationPermission = (response);
      });

    this.form.get('assigneeId').valueChanges.subscribe(name => {
      if (name.hasOwnProperty('isGroup')) {
        if (name.isGroup === true) {
          this.service.getEmailNotificationPermission(true).subscribe(
            (response: EmailNotificationDto[]) => {
              this.emailNotificationPermission = (response);
            });
        }
      }
    });
  }

  getGroupByUserId(id: any): void {
    const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if (pattern.test(id) === false) {
      this.groupState.getGroup(id).subscribe((response) => {
        const res = response;
        if (res.type === GroupTypeStringEnum.All || res.type === GroupTypeStringEnum.RepresentativeRole ||
          res.type === GroupTypeStringEnum.CRMandRepresentativeRole || res.type === GroupTypeStringEnum.ECMandRepresentativeRole) {
          this.isRepRole = true;
        }
      });
    }
  }

  onOptionSelected(event: any): void {
    if (event && event.hasOwnProperty('label')) {
      this.getGroupByUserId(event.ref);
    }
  }

  checkFormChange(): void {
    this.clearTimeoutSb = setTimeout(() => {
      this.initialValue = JSON.stringify(this.form.value);
      this.form.valueChanges.subscribe((value) => {
        if (JSON.stringify(value) !== this.initialValue) {
          this.formChanged.emit(true);
        }
      });
    }, 2000);
  }

  ngAfterViewInit(): void {
    this.checkFormChange();
  }

  recordTaskClick(): void {
    this.task = {
      ...this.form.value,
      attachments: this.form.value.attachments.map(x => x.id)
    };
    if (this.formLinks?.length) {
      this.formLinks.forEach(element => {
        if (element.isProspectInfo === true) {
          element.type = 'Prospect';
        } else if (element.isProspectInfo === false) {
          element.type = 'Member';
        }
        else {
          element.type = element.type;
        }
      });
    }
    this.task.dueDate = this.task.dueDate ? new Date(this.task.dueDate).toJSON() : null;
    this.task.assigneeId = this.selectedUser ? this.selectedUser.ref : '',

      this.task.links = this.formLinks;
    this.task.emails = this.isEmailType() ? this.emailTemplates : [];

    this.state.createTask(this.task).subscribe(data => {
      if (data) {
        this.globalState.updateTasks();
        this.taskService.notifyTaskUpdate();
        if (data.assignee.isGroup === true) {
          let emailAddresses = [];
          emailAddresses = data?.assignedToEmails;
          const CRMType = 'Task';
          const userGroup = data?.assignee?.name;
          const sendEmailPermission = this.emailNotificationPermission?.
            find(item => item.crmObjectType.name.toLowerCase() === CRMType.toLowerCase());

          if (sendEmailPermission?.hasPermission === true) {
            this.globalState.sendBulkEmails(CRMType, emailAddresses, userGroup);
          }
        }
        if (data.assignee.isGroup === false) {
          const CRMType = 'Task';
          const sendRequest = new SendEmailRequestModel();
          sendRequest.to.address = data?.assignedToEmails?.toString();
          const sendEmailPermission = this.emailNotificationPermission?.
            find(item => item.crmObjectType.name.toLowerCase() === CRMType.toLowerCase());

          if (sendEmailPermission?.hasPermission === true) {
            this.globalState.SendEmail(sendRequest, CRMType);
          }
        }
        this.formChanged.emit(false);
        this.toast.success(data.message);
        this.dialogRef.close(true);
        this.closeDialog.emit();
      }
    });
  }

  addInstance(links: SearchMemberProspectOptionsModel[]): void {
    const acc = [];
    links.forEach(item => {
      if (Array.isArray(item)) {
        item.forEach(arrayItem => {
          if (arrayItem) {
            acc.push(new MemberProspectInstancesModel(arrayItem));
          }
        });
      } else if (item) {
        acc.push(new MemberProspectInstancesModel(item));
      }
    });
    this.formLinks = acc;
    this.addIntanceEmit();
  }

  addIntanceEmit(): void {
    if (this.isFirstInstanceLoad) {
      this.isFirstInstanceLoad = false;
      return;
    }
    this.formChanged.emit(true);
  }

  private initializeTaskTemplatesDropdown(taskTemplates: TaskTemplateDto[]): void {
    const taskTemplatesOptions = [NoneTaskTemplateDropdownOption]
      .concat(
        taskTemplates.map<SelectOption<number>>(taskTemplate => ({
          label: taskTemplate.name,
          value: taskTemplate.id
        })));

    this.taskTemplatesOptions = taskTemplatesOptions;
    this.taskTemplates = taskTemplates;

    this.form.controls.templateId.patchValue(NoneTaskTemplateDropdownOption.value, {emitEvent: false});
  }

  private initializeEmptyTaskTemplatesDropdown(): void {
    this.taskTemplatesOptions = [EmptyTaskTemplateDropdownOption];
  }

  private applyTaskTemplate(taskTemplateId: number): void {
    if (!!taskTemplateId) {
      const taskTemplate = this.taskTemplates.find(template => template.id === taskTemplateId);
      // @ts-ignore
      const templateDueDate = moment(moment(new Date())).add(taskTemplate.timeDue.dueIn, taskTemplate.timeDue.dueDateType);

      if (taskTemplate.attachments.length) {
        taskTemplate.attachments.forEach(file => {
          this.uploadFile.createFormGroup(file);
          this.uploadFile.addFileToAttachment(file);
        });
      }

      if (taskTemplate.emails.length) {
        const emailIds = taskTemplate.emails.map(x => x.id.toString());
        this.state.getEmailTemplates(emailIds);
      }

      if (taskTemplate.assigneeId != null) {
        this.selectedUser = {
          label: taskTemplate.assigneeId.type === 'IsGroup' ? taskTemplate.assigneeId.name
            : taskTemplate.assigneeId.firstName + ' ' + taskTemplate.assigneeId.lastName,
          ref: taskTemplate.assigneeId.id,
          type: null,
          value: null
        };
      } else {
        this.selectedUser = {
          label: this.globalState.user.firstName + ' ' + this.globalState.user.lastName,
          ref: this.globalState.user.id,
          type: null,
          value: null
        };
      }

      this.form.patchValue({
        type: taskTemplate.type,
        priority: taskTemplate.priority,
        description: taskTemplate.description,
        dueDate: templateDueDate.utc().toISOString()
      });
    } else {
      this.form.patchValue({
        type: TaskTypeEnum[TaskTypeEnum.General],
        priority: TaskPriorityEnum[TaskPriorityEnum.Low],
        description: '',
        dueDate: ''
      }, {emitEvent: false});
      this.selectedUser = {
        label: this.globalState.user.firstName + ' ' + this.globalState.user.lastName,
        ref: this.globalState.user.id,
        type: null,
        value: null
      };
      this.uploadFile.clear();
      this.taskEmails = [];
    }
  }

  userClicked(data): void {
    this.user.id = data.ref;
  }

  addEmail(selectedIds): void {
    this.emailTemplates = selectedIds;
  }

  isEmailType(): boolean {
    return this.form.controls.type.value === 'Email';
  }

  getOptionText(option): any {
    return option?.label;
  }

  private scrollEvent = (event: any): void => {
    if (this.matAutocompleteTrigger.panelOpen) {
      this.matAutocompleteTrigger.updatePosition();
    }
    const selectedElement = this.htmlElementRef.nativeElement.querySelector('.assignedId-container');

    if (selectedElement && (this.isPanelClosed || this.matAutocompleteTrigger.panelOpen)) {
      const dialogContainer = document.querySelector('.mat-dialog-container');
      if (dialogContainer) {
        const dialogContainerRect = dialogContainer.getBoundingClientRect();
        const dialogContainerTop = dialogContainerRect.top;

        const panelElement = document.querySelector('.mat-autocomplete-panel');
        let panelHeight = panelElement ? panelElement.getBoundingClientRect().height : 96;
        panelHeight = panelHeight > 180 ? panelHeight - 40 : panelHeight;
        const dialogContainerBottom = dialogContainerRect.bottom - (panelHeight);

        const selectedElementRect = selectedElement.getBoundingClientRect();
        const selectedElementTop = selectedElementRect.top;
        const selectedElementBottom = selectedElementRect.bottom;

        const isOutsideDialogBounds =
          selectedElementTop < dialogContainerTop ||
          selectedElementBottom > dialogContainerBottom;

        if (this.matAutocompleteTrigger.panelOpen && isOutsideDialogBounds) {
          this.isPanelClosed = true;
          this.matAutocompleteTrigger.closePanel();
        } else if (!this.matAutocompleteTrigger.panelOpen && !isOutsideDialogBounds && this.isPanelClosed) {
          this.matAutocompleteTrigger.openPanel();
          this.isPanelClosed = false;
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.clearTimeoutSb) {
      clearTimeout(this.clearTimeoutSb);
    }
    this.destroyed$.next();
  }

}
