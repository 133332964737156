import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomSnackbarService } from '@core/services/custom-snackbar.service';
import { GlobalStateService } from '@core/services/global.state.service';
import { CreateConversationStateService } from '@shared/components/conversation/create-conversation.state.service';
import { ConversationType } from '@shared/configs/conversation-types.config';
import { GLOBAL_SETTINGS } from '@shared/constants/global-settings';
import { OPPORTUNITY_STATUS_OPTIONS, PROBABILITY_OPTIONS, REASON_OPTIONS } from '@shared/constants/select-options.constants';
import { AssigneeFormControl } from '@shared/controls/assignee-form-control';
import {
  CampaignsModel, ConversationModel, OpportunityCreateModel,
  SearchMemberOptionsModel, SearchUserOptionsModel
} from '@shared/models';
import { Observable, Subject } from 'rxjs';
import { getEndOfDate } from '@shared/utils';
import { CreateConversationCommonComponent } from '@shared/components/conversation/create-conversation-common.component';
import { takeUntil } from 'rxjs/operators';
import { FileTypeEnum } from '@shared/enums';
import { CmButtonComponent } from '@shared/components/cm-button/cm-button.component';
import { ConversationApiService, TaskApiService, UserGroupManagementApiService } from '@core/services/api.services';
import { EnailNotificationApiService } from '@core/services/api.services/email-notification-api.service';
import { UserManagementStateService } from 'app/modules/setup/user-management/user-management.state.service';
import { GroupTypeStringEnum } from '@shared/enums/group-type.enum';

@Component({
  selector: 'app-create-opportunity',
  templateUrl: './create-opportunity.component.html',
  styleUrls: ['../create-conversation/create-conversation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateOpportunityComponent
  extends CreateConversationCommonComponent
  implements OnInit, AfterViewInit {
  defaultAssigneeUserName = new Subject<SearchUserOptionsModel>();
  conversationUsers$ = this.state.conversationUsers$();
  todayDate = new Date();
  form: FormGroup;

  FileTypeEnum = FileTypeEnum;
  probabilityOptions = PROBABILITY_OPTIONS;
  reasonOptions = REASON_OPTIONS;
  campaignOptions: CampaignsModel[];
  GLOBAL_SETTINGS = GLOBAL_SETTINGS;
  selectedUser: any;

  referrers = [];
  viewInit = false;
  isRepRole = false;

  @ViewChild('referrersBtn', { read: CmButtonComponent })
  referrersBtnContainer: CmButtonComponent;
  constructor(
    fb: FormBuilder,
    state: CreateConversationStateService,
    toast: CustomSnackbarService,
    globalState: GlobalStateService,
    taskService: TaskApiService,
    conversationService: ConversationApiService,
    htmlElementRef: ElementRef,
    service: EnailNotificationApiService,
    private userstate: UserManagementStateService,
    private groupState: UserGroupManagementApiService
  ) {
    super(fb, state, globalState, toast, taskService, conversationService, htmlElementRef, service);
    this.getGroupByUserId(this.globalState.user.id);
    this.selectedUser = {
      label: this.globalState.user.firstName + ' ' + this.globalState.user.lastName,
      ref: this.globalState.user.id
    };
    setTimeout(() => {
      this.viewInit = true;
    }, 0);

    this.form.get('conversationType').patchValue(ConversationType.opportunity);
    this.statusOptions = OPPORTUNITY_STATUS_OPTIONS;

    this.form.addControl('expectedValue', this.fb.control(null));
    this.form.addControl(
      'assigneeId',
      new FormControl('')
    );
    this.form.addControl('declineReason', this.fb.control(null));
    this.form.addControl('expiryDate', this.fb.control(null));
    this.form.addControl('opportunityProbability', this.fb.control('Unknown'));
    this.form.addControl('expectedCloseDate', this.fb.control(null));
    this.form.addControl('campaignId', this.fb.control(-1));
  }

  ngOnInit(): void {


    super.ngOnInit();
    this.globalState
      .campaigns$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        this.campaignOptions = [...data];

        this.campaignOptions.push({
          value: -1,
          label: 'N/A',
        });
      });

    this.form.get('assigneeId').valueChanges.subscribe((name) => {
      if (!name) {
        this.isRepRole = false;
        return;
      }

      if (name?.length >= GLOBAL_SETTINGS.minLengthToTriggerSearch) {
        this.state.getSearchedUserGroups(name, true);
      }
    });
  }

  ngAfterViewInit(): void {
    this.checkFormChanges();
  }

  getGroupByUserId(id: any): void {
    const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if (pattern.test(id) === false) {
      this.groupState.getGroup(id).subscribe((response) => {
        const res = response;
        if (res.type === GroupTypeStringEnum.All || res.type === GroupTypeStringEnum.RepresentativeRole ||
          res.type === GroupTypeStringEnum.CRMandRepresentativeRole || res.type === GroupTypeStringEnum.ECMandRepresentativeRole) {
          this.isRepRole = true;
        }
      });
    }
  }

  onOptionSelected(event: any): void {
    if (event && event.hasOwnProperty('label')) {
      this.getGroupByUserId(event.ref);
    }
  }

  addReferrerInstance(links: SearchMemberOptionsModel[]): void {
    const acc = [];
    links.forEach((item) => {
      if (item.ref) {
        acc.push(item.ref);
      }
    });
    this.referrers = acc;
  }

  saveConversationType(): Observable<ConversationModel> {
    if (this.formLinks?.length) {
      this.formLinks.forEach(element => {
        if (element.isProspectInfo === true) {
          element.type = 'Prospect';
        } else if (element.isProspectInfo === false) {
          element.type = 'Member';
        }
        else {
          element.type = element.type;
        }
      });
    }
    const opportunity: OpportunityCreateModel = {
      subject: this.form.value.subject,
      description: this.form.value.description,
      status: this.form.value.status,
      productId: this.form.value.productId,
      assigneeId: this.selectedUser ? this.selectedUser.ref : '',
      attachments: this.form.value.attachments.map((x) => x.id),
      campaignId:
        this.form.value.campaignId === -1 ? null : this.form.value.campaignId,
      declineReason: this.form.value.declineReason,
      expectedCloseDate: !!this.form.value.expectedCloseDate
        ? getEndOfDate(new Date(this.form.value.expectedCloseDate)).toJSON()
        : null,
      expectedValue: this.form.value.expectedValue,
      expiryDate: !!this.form.value.expiryDate
        ? getEndOfDate(new Date(this.form.value.expiryDate)).toJSON()
        : null,
      opportunityProbability: this.form.value.opportunityProbability,
      links: this.formLinks,
      referrers: this.referrers,
    };
    return this.state.setOpportunity(opportunity);
  }

  getOptionText(option): any {
    return option?.label;
  }

}
